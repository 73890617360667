



















































.toolbar {
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
}

.text-field {
  padding-right: 16px;
  padding-left: 16px;
}
